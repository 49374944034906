
import {Options, Vue, Watch} from "vue-property-decorator";
import {
  CopyOutlined,
  DownloadOutlined,
  DownOutlined,
  EditOutlined,
  HeartOutlined,
  BellOutlined,
  HighlightOutlined,
  InfoCircleOutlined,
  QrcodeOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  SendOutlined,
  UploadOutlined
} from '@ant-design/icons-vue';
import {cancelCollect, clickCollect, editVersion, getAppDetail, getAppVersionList} from "@/api/service/app";
import useClipboard from 'vue-clipboard3'
import * as types from "@/store/constants/user_types";
import {
  GETTER_APP_LIST,
  GETTER_CURRENT_APP_ID,
  GETTER_CURRENT_USER_ID,
  GETTER_LEVEL,
  GETTER_PRIVATE_URL,
  GETTER_TOKEN,
  GETTER_USER_LIST,
  UPDATE_CURRENT_APP_ID
} from "@/store/constants/user_types";
import {Action, Getter, Mutation} from "vuex-class";
import Toast from "@/utils/toast";
import QrcodeVue from 'qrcode.vue';
import AppModal from "@/components/AppModal.vue";
import {getDefaultSrc} from "@/utils/common";
import {
  GETTER_FILTER,
  GETTER_STATE_COLLAPSED,
  READ_NOTIFICATION,
  UPDATE_FILTER,
  UPDATE_NATIVE_READ_LIST
} from "@/store/constants/filter_types";
import {getCertList, reSignificate} from "@/api/service/certification"
import {
  Badge,
  Button,
  Divider,
  Dropdown,
  Empty,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Select,
  Spin,
  Table,
  Tag,
  Tooltip,
  Upload
} from "ant-design-vue";
import {isMobileDevice} from "@/utils/utils";
import {openNewTab} from "@/utils/redirect/router_redirect";
import {E_Lark_Chat_Env, E_Lark_type, sendLarkNotification} from "@/utils/redirect/lark_notification";

@Options({
  components: {
    SearchOutlined,
    DownOutlined,
    EditOutlined,
    DownloadOutlined,
    CopyOutlined,
    QuestionCircleOutlined,
    QrcodeOutlined,
    QrcodeVue,
    UploadOutlined,
    InfoCircleOutlined,
    AppModal,
    HeartOutlined,
    BellOutlined,
    AImage: Image,
    ATooltip: Tooltip,
    AEmpty: Empty,
    ATextarea: Input['TextArea'],
    AButton: Button,
    AUpload: Upload,
    AModal: Modal,
    AForm: Form,
    AFormItem: Form['Item'],
    ADropdownButton: Dropdown['Button'],
    ATable: Table,
    AInputGroup: Input['Group'],
    AInput: Input,
    ADivider: Divider,
    ATag: Tag,
    ASpin: Spin,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu['Item'],
    ABadge: Badge,
    ASelect: Select,
    ASelectOption: Select['Option'],
    SendOutlined,
    HighlightOutlined
  }
})

export default class App extends Vue {
  @Getter(GETTER_LEVEL)
  private roleLevel: any;
  @Getter(GETTER_CURRENT_USER_ID)
  private currentUserId: any;
  @Getter(GETTER_APP_LIST)
  private appList: any;
  @Action(types.UPDATE_USER_LIST)
  private updateUserList: any;
  @Getter(GETTER_USER_LIST)
  private userList: any;
  @Getter(GETTER_CURRENT_APP_ID)
  private currentAppId: any;
  @Mutation(UPDATE_CURRENT_APP_ID)
  private updateCurrentAppId: any;
  @Getter(GETTER_TOKEN)
  private token: any;
  @Getter(GETTER_PRIVATE_URL)
  private privateUrl: any;
  @Mutation(UPDATE_FILTER)
  private updateFilter: any;
  @Getter(GETTER_FILTER)
  private getterFilters: any;
  @Action(READ_NOTIFICATION)
  private readNotification: any;
  @Mutation(UPDATE_NATIVE_READ_LIST)
  private updateNativeReadList: any;
  @Getter(GETTER_STATE_COLLAPSED)
  private stateCollapsed: any;

  private table_loading = false;
  private permit_loading = false;
  private current_app: any = null;
  private edit_usernames: string[] = [];
  private edit_user_ids: string[] = [];
  private download_usernames: string[] = [];
  private download_user_ids: string[] = [];
  private pageNo = 1;
  private dataSource: any[] = [];
  private annexList: any[] = [];
  private locale: any = {emptyText: "暂无数据"};
  private confirmLoading = false;
  private collectLoading = false;
  private subscribeLoading = false;
  private tableHeight: number = document.body.clientHeight - 390;
  private paginationHeight = 32;
  private showPagination = false;
  private pagination = {
    total: 0,
    current: 1,
    pageSize: 20,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],//每页中显示的数据
    showTotal: (total: any) => `共有 ${total} 条数据`
  };
  private columns: any[] = [
    {
      title: '版本',
      dataIndex: 'version',
      key: 'version',
      width: this.isMobile ? 186 : 216,
      fixed: this.isMobile ? '' : 'left'
    }, {
      title: 'Build',
      dataIndex: 'incId',
      key: 'incId',
      width: 72,
      fixed: this.isMobile ? '' : 'left'
    },
    {
      title: "Bundle ID",
      dataIndex: 'bundleId',
      key: 'bundleId',
      width: 265
    },
    {
      title: "大小",
      dataIndex: 'appSize',
      key: 'appSize',
      width: 100
    },
    {
      title: '备注',
      dataIndex: 'description',
      key: 'description',
      // width: "40%"
      ellipsis: true
    },
    {
      title: '上传者',
      dataIndex: 'uploadBy',
      key: 'uploadBy',
      width: 110
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: 200
    },
    {
      title: '操作',
      key: 'action',
      width: this.isMobile ? 80 : 100,
      fixed: 'right'
    }
  ];

  private modalTitle = "";
  private visible = false;
  private packageModalVisible = false;
  private currentVersion = "";
  private packageId = "";
  private formState: any = {
    appType: 'iOS'
  };
  private c_description = "";
  private labelCol: any = {span: 24};
  private wrapperCol: any = {span: 24};

  private noAppImg = require("@/assets/no-apps.png");

  private certList: any[] = [];

  private signModalVisible = false;
  private reSignVersionId = "";
  private certId: any = "";

  get isIOS() {
    return this.app.appType === 'iOS';
  }

  get app() {
    return this.appList.find((v: any) => v.appId === this.currentAppId);
  }

  get privateAddon() {
    return this.privateUrl + this.defaultPath
  }

  get typeIcon() {
    return this.app.appType === 'iOS' ? require("@/assets/apple-fill.svg") : require("@/assets/android-fill.svg");
  }

  get defaultPath() {
    return this.formState.appType === 'iOS' ? '/ios/' : '/android/';
  }

  get fullPath() {
    return this.isMobile ? this.app.downloadUrl : "下载地址: " + this.app.downloadUrl;
  }

  // 判断是否满足编辑权限
  get satisfiedEditPower() {
    return this.roleLevel === 100;
  }

  get noAppTitle() {
    return this.satisfiedEditPower ? "您还没有添加应用" : "您还没有应用";
  }

  get noAppContent() {
    return this.satisfiedEditPower ?
        "请添加应用，随后将应用安装包一键上传，内测用户即可通过短链接或扫描二维码一键安装。" :
        "请联系管理员添加应用，随后您可以选择应用将安装包一键上传，内测用户即可通过短链接或扫描二维码一键安装。";
  }

  get isMobile() {
    return isMobileDevice();
  }

  @Watch("currentAppId", {immediate: true})
  private currentAppIdChange(val: string, oldVal: string) {
    console.log("currentAppIdChange", oldVal);
    if (this.currentAppId) {
      Promise.all([this.fetchAppDetail(), this.getPackageList()]).then(() => {
        this.calcBottomPosition();
      }).catch((e: any) => {
        console.log('e', e);
      })
      // this.fetchAppDetail();
      // this.getPackageList();
      // 当前的app有unread的message
      if (this.app.unreadMessage && oldVal) {
        this.readNotification({appId: this.currentAppId});
        this.updateNativeReadList(this.currentAppId);
      }
    }
  }

  private groupFilter(input: string, option: any) {
    console.log("groupFilter",option );
    return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  private getDefault(appType: "iOS" | "Android") {
    return getDefaultSrc(appType);
  }

  private toPublish() {
    const {href} = this.$router.resolve({
      name: "publish"
    });
    openNewTab(href);
  }

  private fetchDetail(silent = false) {
    this.fetchAppDetail(silent);
  }

  private reset() {
    this.visible = false;
    this.modalTitle = "";
  }

  private robotDistribute(version_id: string, isTest: boolean) {
    sendLarkNotification({
      appId: this.currentAppId,
      appVersionId: version_id,
      receiveType: E_Lark_type.chat,
      chatEnv: isTest ? E_Lark_Chat_Env.DevTest : E_Lark_Chat_Env.PreFavTest
    }, false)
  }

  private sendLarkMessageToViewers(version_id: string) {
    sendLarkNotification({
      appId: this.currentAppId,
      appVersionId: version_id,
      receiveType: E_Lark_type.view_app,
    }, false)
  }

  private async getPackageList() {
    try {
      this.table_loading = true;
      const result: any = await getAppVersionList(
          this.currentAppId,
          this.pagination.current,
          undefined,
          this.pagination.pageSize
      );
      const {data, total} = result;
      this.pagination = {
        ...this.pagination,
        total: total
      }
      this.showPagination = total > this.pagination.pageSize;
      this.dataSource = data;
      this.table_loading = false;
    } catch (e) {
      this.table_loading = false;
      Toast.show("error", e);
    }
  }

  private async editApp() {
    this.visible = true;
    this.modalTitle = "App设置"
    this.formState = {
      ...this.current_app,
      users: null
    };
    const url = this.app.downloadUrl;
    const index = url.indexOf(this.defaultPath);
    console.log("index===", index);
    // 处理异常，链接与appType不匹配的情况，以appType为准
    if (index > -1) {
      this.formState.path = url.substring(index + this.defaultPath.length);
      console.log("this.formState.path", this.formState.path);
      if (this.privateUrl) {
        this.formState.publicPath = this.app.supportUrl.substring(this.privateAddon.length);
      }
    } else {
      const lastIndex = url.lastIndexOf("/");
      console.log("lastIndex", lastIndex);
      this.formState.path = url.substring(lastIndex + 1);
      if (this.privateUrl) {
        const last_support_index = this.app.supportUrl.lastIndexOf("/");
        this.formState.publicPath = this.app.supportUrl.substring(last_support_index + 1);
      }
    }
    if (this.userList.length === 0 && this.satisfiedEditPower) {
      await this.updateUserList({
        refresh: true
      });
    }
  }

  private async toggleCollect(appId: string, collected: boolean) {
    try {
      this.collectLoading = true;
      collected ? await cancelCollect(appId, 'collect') : await clickCollect(appId, 'collect');
      this.fetchDetail(true);
      if (this.getterFilters.viewCollect) {
        this.updateFilter({
          refresh: !this.getterFilters.refresh
        })
      }
      this.collectLoading = false;
    } catch (e) {
      Toast.show("error", e);
      this.collectLoading = false;
    }
  }

  private async toggleSubscribe(appId: string, subscribed: boolean) {
    try {
      this.subscribeLoading = true;
      subscribed ? await cancelCollect(appId, 'subscribe') : await clickCollect(appId, 'subscribe');
      this.fetchDetail(true);
      Toast.show("success", subscribed ? "取消订阅成功" : "订阅成功");
      this.subscribeLoading = false;
    } catch (e) {
      this.subscribeLoading = false;
      Toast.show("error", e);
    }
  }

  private calcBottomPosition() {
    // 动态计算滚动条
    this.$nextTick(() => {
      if (this.$refs.can_show_box) {
        const ref_height: string = window.getComputedStyle(this.$refs.can_show_box as any).height;
        this.tableHeight = document.body.clientHeight - 246 - (ref_height ? parseInt(ref_height) : 0) - (this.showPagination ? this.paginationHeight : 0);
      } else {
        this.tableHeight = document.body.clientHeight - 246 - (this.showPagination ? this.paginationHeight : 0);
      }
    })
  }

  private async fetchAppDetail(silent = false) {
    try {
      this.edit_usernames = [];
      this.download_usernames = [];
      this.download_user_ids = [];
      this.edit_user_ids = [];
      this.permit_loading = false;
      // 访问之前置空
      if (!silent) {
        this.current_app = null;
      }
      this.current_app = await getAppDetail(this.currentAppId);
      this.permit_loading = true;
      this.current_app.users.forEach((v: any) => {
        if (v.permit === "view") {
          this.download_user_ids.push(v.userId);
          this.download_usernames.push(v.username);
        } else if (v.permit === "edit") {
          this.edit_user_ids.push(v.userId);
          this.edit_usernames.push(v.username);
        }
      });
    } catch (e) {
      console.log("e", e);
      this.permit_loading = false;
      Toast.show("error", e);
    }
  }

  private async copy() {
    try {
      const {toClipboard} = useClipboard()
      await toClipboard(this.app.downloadUrl);
      Toast.show("success", "已复制到剪切板")
    } catch (e) {
      Toast.show("warning", e);
    }
  }

  handleTableChange(pagination: any) {
    this.pagination = {
      ...this.pagination,
      current: pagination.current,
      pageSize: pagination.pageSize
    }
    this.getPackageList();
  }

  private goHref() {
    openNewTab(this.app.downloadUrl);
  }

  private rowClick(item: any) {
    return {
      onClick: (e: any) => {
        if (e.target.innerText.trim() !== "操作") {
          openNewTab(`${this.app.downloadUrl}?id=${item.id}`);
        }
      }
    }
  }

  private editApplication(
      id: string, description: string,
      attachFiles: any,
      appVersion: string,
      build: string
  ) {
    this.packageModalVisible = true;
    this.currentVersion = appVersion + " (build " + build + ")";
    this.packageId = id;
    this.c_description = description;
    if (attachFiles && JSON.parse(attachFiles).length > 0) {
      const annexList = JSON.parse(attachFiles);
      annexList.map((annex: any) => {
        annex.status = "done";
      })
      this.annexList = annexList;
    }
  }

  private async packageModalOk() {
    try {
      this.confirmLoading = true;
      await editVersion(this.packageId, this.currentAppId, this.c_description);
      Toast.show("success", "保存成功");
      this.getPackageList();
      this.packageModalReset();
      this.confirmLoading = false;
    } catch (e) {
      this.confirmLoading = false;
      Toast.show("error", e);
    }
  }

  private signModalReset() {
    this.signModalVisible = false;
    this.certId = undefined;
    this.reSignVersionId = "";
    this.currentVersion = "";
  }

  private async signModalOk() {
    try {
      this.confirmLoading = true;
      await reSignificate(this.certId, this.reSignVersionId);
      this.getPackageList();
      this.confirmLoading = false;
      Toast.show("success", `${this.currentVersion}重签名成功`);
      this.signModalReset();
    } catch (e) {
      this.confirmLoading = false;
      Toast.show("error", e);
    }
  }

  private async copyPackageUrl(id: string) {
    try {
      const {toClipboard} = useClipboard()
      await toClipboard(`${this.app.downloadUrl}?id=${id}`);
      Toast.show("success", "已复制到剪切板");
      // 复制链接同时主动触发推送飞书消息
      sendLarkNotification({
        appId: this.currentAppId,
        receiveType: E_Lark_type.self,
        appVersionId: id
      });
    } catch (e) {
      Toast.show("warning", e);
    }
  }

  private downloadPackage(url: string) {
    url && (location.href = url);
  }

  private async downloadAnnex(files: string[]) {
    for (let i = 0; i < files.length; i++) {
      const fileUrl = files[i];

      // 创建 <a> 标签
      const link = document.createElement('a');

      // 设置 <a> 标签的 href 属性为文件 URL
      link.href = fileUrl;

      // 设置 <a> 标签的 download 属性为文件名
      link.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);

      // 将 <a> 标签隐藏起来
      link.style.display = 'none';

      // 添加 <a> 标签到文档中
      document.body.appendChild(link);

      // 模拟点击下载
      link.click();

      // 等待一段时间再处理下一个文件的下载
      await this.download_delay(1000);

      // 移除 <a> 标签
      document.body.removeChild(link);
    }
  }

  private download_delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private packageModalReset() {
    this.packageId = "";
    this.packageModalVisible = false;
    this.c_description = "";
    this.currentVersion = "";
    this.annexList = [];
  }

  private beforeAnnexUpload() {
    return false;
  }

  private isExpired(time: string) {
    const timestamp = new Date(time).getTime();
    const nowTime = new Date().getTime();
    return timestamp <= nowTime;
  }

  private changeCert(val: string) {
    console.log("val", val);
    this.certId = val;
  }

  private async reSign(versionId: any, bundleId: any, appVersion: any, build: any) {
    try {
      if (this.certList.length === 0) {
        this.certList = await getCertList();
      }
      const certItem = this.certList.find((v: any) => bundleId === v.mainBundleId);
      this.certId =
          certItem ?
            certItem.id :
            this.certList.length > 0 ?
                this.certList[0].id :
                undefined;
      this.reSignVersionId = versionId;
      this.currentVersion = appVersion + " (build " + build + ")";
      this.signModalVisible = true;
    } catch (e) {
      Toast.show("error", e);
    }
  }
}
