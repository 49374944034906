import {sendLarkMessage} from "@/api/service/notification";
import Toast from "@/utils/toast";

type T_LarkParams = {
  appId: string;
  appVersionId?: string;
  receiveType: E_Lark_type;
  chatEnv?: E_Lark_Chat_Env;
  userIds?: string[]
}

/*
* receiveType 取值情况如下：
1、 self ：该情况给当前登录用户发送
2、 chat ：该情况给群聊发送消息，此时chatEnv参数需要传，DevTest表示给测试群发，PreFavTest表示给发布群发
3、 subscribe ：该情况给订阅用户群发，暂未实现
4、 view_app ： 该情况给可见权限的用户群发
* */
export enum E_Lark_type {
  self = 'self',
  chat = 'chat',
  subscribe = 'subscribe',
  view_app = 'view_app',
  user_ids = 'user_ids',
}

export enum E_Lark_Chat_Env {
  DevTest = 'DevTest',
  PreFavTest = 'PreFavTest'
}

export enum E_Lark_Notify_type {
  dev = "dev",
  publish = "publish",
  no = "no"
}

export const C_Publish_Role_User = ['wade', 'bob', 'robotd'];

export const sendLarkNotification = async (params: T_LarkParams, silent = true) => {
  try {
    await sendLarkMessage(params);
    !silent && Toast.show("success", "消息已推送至飞书");
  } catch (e) {
    !silent && Toast.show("error", e);
  }
}

export const getDefaultNotificationValue = (username: string) => {
  const isPublishMan = C_Publish_Role_User.some((v) => v.toLowerCase() === username?.toLowerCase());
  return isPublishMan ? E_Lark_Notify_type.publish : E_Lark_Notify_type.dev;
}
