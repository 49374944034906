export const openNewTab = (url: string, open_side = true) => {
  const lark = window.lark;
  // 如果 URL 没有包含域名，则使用当前页面的 location.origin 补全 URL
  const fullUrl = /^(https?:\/\/)/i.test(url) ? url : `${window.location.origin}${url}`;
  if (typeof lark !== 'undefined' && lark) {
    // 使用飞书内置的方法打开链接
    if (lark.openLink) {
      lark.openLink({
        url: fullUrl
      });
    } else {
      // 飞书客户端
      if (open_side) {
        window.location.href = `https://applink.feishu.cn/client/web_url/open?url=${encodeURIComponent(fullUrl)}&mode=sidebar-semi`;
      } else {
        window.location.href = url;
      }
    }
  } else {
    // 如果不在飞书环境中，则使用 window.open 打开新标签页
    window.open(url, "_blank");
  }
}
