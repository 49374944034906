export const getDefaultSrc = (platform: 'iOS' | 'Android') => {
    return platform === 'iOS' ?
        require("@/assets/default.svg") :
        require("@/assets/a_default.svg")
}


export const removeNoSenseKeys = (resource: any) => {
    for (const key in resource) {
        // if (resource.hasOwnProperty(key)) {
        if (Object.prototype.hasOwnProperty.call(resource, key)) {
            resource[key] === undefined || resource[key] === null
                ? delete resource[key]
                : void 0;
        }
    }
    return resource;
};

// 判断手机 - ios/andriod
function isIOS() {
    const u = navigator.userAgent;
    return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
}

/**
 * @description: 键盘弹起，输入框被遮挡
 */
function judgeInput() {
    if (!isIOS()) {
        window.addEventListener('resize', function () {
            if (
                document.activeElement &&
                (
                    document.activeElement.tagName === 'INPUT' ||
                    document.activeElement.tagName === 'TEXTAREA'
                )
            ) {
                setTimeout(function () {
                    document.activeElement &&
                    (document.activeElement as any).scrollIntoViewIfNeeded &&
                    (document.activeElement as any).scrollIntoViewIfNeeded();
                }, 0);
            }
        });
    }
}

/**
 * 根据时间戳，获取不通格式的时间
 * @param date
 * @param fmt 获取的格式   yyyy-MM-dd hh:mm:ss.S 输出   2020-11-17 14:09:04.33  输入hh:mm:ss 输出14:09:04
 * 输入 yyyy-qq/MM/dd 输出 2020-04/11/17 年季度月日
 */
function timestampToTime(date: number | Date, fmt: string) {
    const newDate = new Date(date);
    const o: {
        [propName: string]: number;
    } = {
        "M+": newDate.getMonth() + 1, // 月份
        "d+": newDate.getDate(), // 日
        "h+": newDate.getHours(), // 小时
        "m+": newDate.getMinutes(), // 分
        "s+": newDate.getSeconds(), // 秒
        "q+": Math.floor((newDate.getMonth() + 3) / 3), // 季度
        S: newDate.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (newDate.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    }
    for (const k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length === 1
                    ? "" + o[k]
                    : ("00" + o[k]).substr(("" + o[k]).length)
            );
        }
    }
    return fmt;
}

function areArraysEqual(arr1: any[], arr2?: any[]) {
    if (arr1 && arr2) {
        if (arr1.length !== arr2.length) return false; // 长度不同，直接返回 false
        return arr1.every((item, index) => item === arr2[index]);
    }
    return false;
}

export {
    isIOS,
    judgeInput,
    timestampToTime,
    areArraysEqual
}
