import { BaseApi } from "@/api/base/base";
import * as PATH_URL from "@/api/constants/path";
import axios from "axios";

export const getAppList = async (params, pageNo, pageSize) => {
  try {
    const result = await BaseApi.get(PATH_URL.APPS, {
      params: {
        ...params,
        pageNo,
        pageSize
      },
    })
    return result;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getAppVersionList = async (appId, pageNo, password, pageSize) => {
  try {
    const result = await BaseApi.get(PATH_URL.APP_VERSION, {
      params: {
        appId,
        pageNo,
        password,
        pageSize
      }
    })
    return result;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getAppVersionDetail = async (url, password, preview, id, token) => {
  try {
    const config = {
      params: {
        url,
        password,
        id,
        preview
      }
    };
    token && (config.supply = {
      "app-version-token": token
    })
    const result = await BaseApi.get(PATH_URL.APP_VERSION_DETAIL, config);
    return result;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const editVersion = async (id, appId, description) => {
  try {
    const result = await BaseApi.post(PATH_URL.APP_VERSION_DETAIL, {
      id, description, appId
    })
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const editApp = async (params) => {
  try {
    const result = await BaseApi.post(PATH_URL.APP, params)
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getAppDetail = async (appId) => {
  try {
    const result = await BaseApi.get(PATH_URL.APP, {
      params: {
        appId
      }
    })
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const uploadPackage = async (
  file,
  attachFiles,
  appId,
  description,
  callback
) => {
  try {
    const formData = new FormData();
    file && formData.append("file", file);
    // attachFiles && formData.append("attachFiles", attachFiles);
    if (attachFiles) {
      attachFiles.forEach((file) => {
        formData.append("attachFiles", file);
      })
    }
    appId && formData.append("appId", appId);
    formData.append("description", description);
    const result = await BaseApi.post(PATH_URL.UPLOAD_PACKAGE, formData, {
      timeout: 300000,
      onUploadProgress: (progressEvent) => {
        if (progressEvent.lengthComputable) {
          // console.log("progressEvent", progressEvent);
          const upLoadProgress = progressEvent.loaded / progressEvent.total * 100;
          // console.log("upLoadProgress", progressEvent);
          callback(upLoadProgress, progressEvent);
        }
      }
    });
    console.log("uploadPackage result", result);
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getSignedUrl = async (fileKey, fileSize) => {
  try {
    const result = await BaseApi.post(PATH_URL.MULTI_SIGNED_URL_UPLOAD, {
      functionName: 'start',
      fileKey,
      fileSize
    })
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const fetchCategoryList = async () => {
  try {
    const result = await BaseApi.get(PATH_URL.CATEGORY_LIST, {
      params: {
        pageSize: 1000
      }
    })
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const editCategory = async (params) => {
  try {
    const result = await BaseApi.post(PATH_URL.CATEGORY_PATH, params)
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const delCategory = async (id) => {
  try {
    const result = await BaseApi.delete(PATH_URL.CATEGORY_PATH, {
      params: {
        id
      }
    })
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

// appCollectType传值：收藏时，传collect，订阅时，传 subscribe
export const clickCollect = async (id, appCollectType) => {
  try {
    const result = await BaseApi.post(PATH_URL.COLLECT, {
      appIds: [id],
      appCollectType
    })
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const cancelCollect = async (id, appCollectType) => {
  try {
    const result = await BaseApi.post(PATH_URL.CANCEL_COLLECT, {
      appIds: [id],
      appCollectType
    })
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getUploadUrl = async (appFileName, attachFileNames) => {
  try {
    const result = await BaseApi.post(PATH_URL.SIGNED_URL, {
      appFileName,
      attachFileNames
    })
    // return Promise.reject("e", result);
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getUploadApp = async (appId, description, s3Url, attachFileUrls, chatEnv) => {
  try {
    const result = await BaseApi.post(PATH_URL.V2_UPLOAD_APP, {
      appId,
      description,
      s3Url,
      attachFileUrls,
      chatEnv
    })
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const uploadPart = async (signed_url, part_data, partNo, callback, retry_times = 0) => {
  try {
    const result = await axios.put(signed_url, part_data, {
      timeout: 60000,
      onUploadProgress: progressEvent => {
        const { loaded } = progressEvent;
        callback(loaded);
      }
    })
    return {
      partNumber: partNo,
      tag: JSON.parse(result.headers.etag)
    };
  } catch (e) {
    console.log('uploadPart', partNo, e);
    if (e && (e.code === 'ERR_NETWORK' || e.code === 'ECONNABORTED') && retry_times < 3) {
      // 当包比较大时容易直接失败，增加重试逻辑
      retry_times ++;
      return await uploadPart(signed_url, part_data, partNo, callback, retry_times);
    }
    return Promise.reject(e);
  }
}

export const completeUploadPart = async (fileKey, uploadId, tags) => {
  try {
    const result = await BaseApi.post(PATH_URL.MULTI_SIGNED_URL_UPLOAD, {
      functionName: 'finish',
      fileKey,
      uploadId,
      tags
    })
    return result;
  } catch (e) {
    return Promise.reject(e);
  }
}
